@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Poppins:wght@500&display=swap');
.feature{
    width:100%;
    height:fit-content;
    background-color: #f3fdff;
    padding:2vh 6vw;
}
.feature>h1{
    font-family: 'Montserrat', sans-serif;
    font-size: x-large;
    /* font-size: 50px; */
    /* max-width:15rem; */
    color: #272c60;

}
.feature_body{
    height:fit-content;
    display: flex;
    flex-direction: column;
    gap:4rem;
}
.feature_heading{
    font-size: x-large;
    font-family: 'Montserrat', sans-serif;
    color: #272c60;
}
.retailer_feature,.distributer_feature{
    padding:2rem 0px;
    padding-right: 2rem;
    list-style: none;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    gap:3rem;
}
.retailer_feature::-webkit-scrollbar,.distributer_feature::-webkit-scrollbar{
    display: none;
}
@media screen and (min-width:400px)
{
    .feature_heading{
        font-size: xx-large;
    }
    .feature>h1{
        font-size: xx-large;
        max-width:28rem;
    }
    .retailer_feature,.distributer_feature{
        gap:4rem;
    }
}
@media screen and (min-width:650px)
{
    .feature>h1{
        max-width:28rem;
    }
    .retailer_feature,.distributer_feature{
        gap:4.8rem;
    }
}
@media screen and (min-width:900px)
{
    .feature>h1{
        font-size: 40px;
        max-width:44rem;
    }
    .retailer_feature,.distributer_feature{
        gap:5.6rem;
    }
}
