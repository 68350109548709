@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap');

#model1{
    background-color: #f3fdff;
}
.model-title
{
    text-align: center;
    margin: 100px auto auto 0;
    font-size: 50px;
    color: #272c60;
}

.model-divider
{
    width: 75px;
    height: 4px;
    background-color: #272c60;
    position: relative;
    margin: 20px auto 60px;
}

.members
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.model1_member
{
    margin: 20px 15px;
    border-radius: 10px;
    padding: 0;
    height: 300px;
    overflow: hidden;
}
.model1_member>img{
    width: 300px;
    height: 300px;
}
#model1 .description
{
    width: 300px;
    height: 325px;
    position: relative;
    background-color: rgba(8,61,119,0.5);
    transform: translateY(0);
    border-radius: 15px;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#model1 .model1_member
{
    cursor: pointer;
    transition: all 1s;
}

#model1 .model1_member:hover
{
    transform: scale(1.01);
}

#model1 .model1_member:hover .description
{
    transform: translateY(-100%);
}

#model1 .description h1
{
    color: #fff;
}


#model1 .description>h2
{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #F21B3F;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0;
}

#model1 .description p
{
    color: #fff;
    /* text-align: justify; */
    padding: 0 15px;
    font-family: 'Merriweather', serif;

}

#model1 .description .social-media svg
{
    color: #fff;
    font-size: 35px;
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.5s;
}

#model1 .description .social-media svg:hover
{
    transform: scale(1.1);
    color: #F21B3F;
}