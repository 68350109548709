@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.testimonial-title
{
    text-align: center;
    margin: 100px auto auto 0;
    font-size: 50px;
    color: #272c60;
}
.testimonial-divider
{
    width: 75px;
    height: 4px;
    background-color: #272c60;
    position: relative;
    margin: 20px auto 60px;
}
.Testimonial-container {
  text-align: center;
  margin: 0 auto;
  font-family: "Poppins", "sans-serif";
  background-color: #f3fdff;
}
.testimonial-container {
  display: flex;
  flex-flow: column;
}

.avatars {
  border-radius: 50%;
  height: 120px;
  margin-top: -6em;
  border: 3px solid rgba(8,61,119);
}

.cards {
  display: flex;
  width: 100%;
}

.card {
  margin: 5em 2em 0em;
  background-color: ivory;
  padding: 2em;
  width: 35%;
  height: 200px;
  flex: 1; /* each card is of equal size */
  box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
}

.leftCard {
  margin-right: -15em;
  margin-top: 7em;
}

.rightCard {
  margin-left: -15em;
  margin-top: 7em;
}

.active-testimonial{
  z-index: 1;
  height: 250px;
  background-image: url("../utils/quote-left.svg"), url("../utils/quote-right.svg");
  background-repeat: no-repeat;
  background-position-x: 0, right;
  background-position-y: 0, bottom;
  background-size: 50px;
  background-origin: content-box;
}

.arrow-container {
  display: flex;
  justify-content: space-between;
  margin-top: -10em;
  position: relative;
}

.arrow {
  height: 3em;
}

.arrow:active {
  fill: lightgray;
}

.memeber_name {
  margin-top: 0.5em;
  margin-bottom: -0.2em;
}

p {
  padding: 0em 2em;
}
.testimony{
  height: 40px;
  /* overflow: scroll; */
}
.testimony::-webkit-scrollbar{
  display:none;
}

.text {
  height: 120px;
  overflow: hidden;
}
.text_main{
  height:160px;
  overflow:hidden;
}
.testimony_main{
  height: 100px;
  overflow: scroll;
  padding-bottom:12px;
  margin: 0;
}
.testimony_main::-webkit-scrollbar{
  display:none;
}
.tracker {
  display: flex;
  justify-content: center;
  /* height: 2.em; */
  margin-top: 1em;
}

.circle {
  transform: scale(0.3);
}

.viewAll {
  background-color: white;
  border: none;
  border-radius: 25px;
  height: 2em;
  width: 20%;
  font-weight: bold;
  margin: 2em auto;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.36);
}

.viewAll:hover {
  background-color: lightgray;
}

.viewAll:active {
  background-color: grey;
}
@media screen and (max-width: 700px) {
  .leftCard {
    display: none;
  }

  .rightCard {
    display: none;
  }
  /* .testimony{
    height: 90px;
    overflow: scroll;
  } */
}