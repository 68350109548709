.privacy_policy{
    padding:3rem 2rem;
}
.app_usage{
    list-style: disc;
}
.personal_info{
    list-style: disc;
}
.Information_automatically_collected{
    list-style: disc;
}
.item_content{
    font-weight: 400;
}