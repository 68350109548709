#about{
    width:100%;
    background-color:#f3fdff;
    box-sizing: border-box;
    padding: 0 1rem;
    
    display:flex;
    flex-direction: column;
    gap:0.8rem;
    }
    .about_heading{
      font-family: 'Montserrat', sans-serif;
      font-size: xx-large;
      text-decoration:4px underline;
      width: fit-content;
      color: #272c60;
      text-underline-offset: 24px;
    }
    .about_img{
      width: 100%;
    }
    .about_img>img{
      width: 90vw;
      
    }
    .about_body{
      display:flex;
      gap:2rem;
      flex-direction: column;
    }
    .about_desc{
      display:none;
    }
    .about_body_img{
      display: flex;
      flex-direction: column;
      align-items: center; 
    }
    .about_body_img>img{
      display: none;
      width: 300px;
      height:300px;
    }
    .about_desc>p{
        display:none;
        font-family: 'Poppins', sans-serif;

    }
    @media screen and (min-width:750px)
    {
        .about_body{
            gap:2rem;
            align-items: center;
        }
        .about_desc{
          display:flex;
          flex-direction: column;
          padding:0 1.6rem;
          align-items: flex-start;
        }
        .about_body_img{
          display: flex;
          flex-direction: column;
          align-items: center; 
        }
        .about_body_img>img{
          width: 450px;
          height:450px;
        }
        .about_body_img>h2{
          display: none;
        }
        .about_desc>p{
            display:block;
            margin:0;
            font-size: large;
            padding:0 0.4rem;
            text-align: start;

        }
    }
    @media screen and (min-width:950px) {
      .about_body{
        flex-direction: row;
      }
      .about_desc{
        padding: 0 1rem;
      }
      .about_desc>h2{
        font-size: x-large;
      }
      .about_desc>p{
        padding:0 1rem;
        font-size: small;
      }
    }
    @media screen and (min-width:1150px) {
      #about{
        padding: 0 1rem;
      }
      .about_body{
        flex-direction: row; 
        justify-content: space-evenly;
        /* align-items: center; */
        gap:8rem;
      }
      .about_img>img{
        height: 70vh;
      }
      .about_body_img{
        width:600px;
        height:600px;
      }
      .about_body_img{
        justify-content: center;
      }
      .about_body_img>img{
        display: block;
        width: 450px;
        height:450px;
      }
      .about_desc{
        width:32rem;
        gap:1.5rem;
      }
      .about_desc>h2{
        font-size: x-large;
      }
      .about_desc>p{
        padding:0 0rem;
        font-size: large;
      }
    }
    @media screen and (min-width:1450px){
      #about{
        padding: 0 3rem;
      }
      .about_body{
        padding:0 6rem;
        padding-right: 1rem;
        }
      .about_desc{
        width:45rem;
        gap:2rem;
      }
      .about_desc>a{
        padding:1rem;
      }
      .about_body_img>img{
        width: 550px;
        height:550px;
      }
    }
    /* .about_desc{ */
        /* display:flex; */
    /* } */
    /* .about_body>img{
        scale: calc(1);
    } */

    /* .about-text h2{
    font-size: 2rem;
    color:#00b7ff;
      font-weight: 500;
      margin: 0px;
    padding: 0px;
    }
    .about-text p{
      width: 80%;
    font-size: 1.2rem;
    color:#272727;
    
    }
    .about-text button{
      margin-top: 20px;
    width: 140px;
    height: 45px;
    border-radius: 10px;
    border: none;
      outline: none;
      color: #ffffff;
    background-color:#00b7ff;
    }
    .about-text button:hover{
    background-color: rgb(0, 132, 255);
      transition: all ease 0.3s;
      color: #ffffff;
    }
    .about_image{
      width: 50%;
    }
    .about-image img{
    width: 600px;
    }
    .about_image>p{
      padding:0;
    }
    .about_image>h2{
      font-size: xx-large;
    } */