#model4{
    width:fit-content;
    height:300px;
    /* display: inline; */
}
.model-title
{
    text-align: center;
    margin: 100px auto auto 0;
    font-size: 50px;
    color: #272c60;
}

.divider
{
    width: 75px;
    height: 4px;
    background-color: #272c60;
    position: relative;
    margin: 20px auto 60px;
}

.model4_members
{
    display: flex;
    width:100vw;
    overflow: scroll;
    align-items: center;
    gap: 24px;
    padding: 2.2rem;
}
.model4_members::-webkit-scrollbar{
    display:none;
}
.model4_member>img{
    width: 125px;
    height:125px;
}
.model4_member:hover
{
    transform: scale(1);
}
#model4 .model4_member
{
    margin: 20px 15px;
    /* width: 250px; */
    height: 250px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 1s;
}
.description>h1 {
    padding-top:20px;
    padding-bottom:0;
    font-size: x-large;
}
.description>h2 {
    padding:0;
    margin:0;
    padding-bottom:24px;
    font-size: smaller;
}
#model4 .model4_member:hover
{
    transform: scale(1.1);
}

#model4 .description
{
    background-color: #fff;
    width: 275px;
    height: 125px;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;    
    padding:0;
    transition: all 1s;
}

#model4 .description:hover
{
    background-color: rgba(8,61,119,0.2);
}

#model4 img
{
    position: absolute;
    top: 40px;
    border-radius: 50%;
    border: 3px solid rgba(8,61,119);
}

#model4 .social-media
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 12px 12px;
    height: 6px;
    background-color: rgba(8,61,119);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#model4 .social-media svg
{
    display: block;
    width: 33%;
    height: 100%;
    font-size: 20px !important;
    background-color: rgba(8,61,119);
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    color: #fff;
    transition: all 1s;
}

#model4 .social-media svg:hover
{
    background-color: red;
}
#model4 .description h1
{
    color: rgba(8,61,119);
    margin:0;
}

#model4 .description h2
{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #F21B3F;
    font-weight: 600;
}

#model4 .description p
{
    color: rgba(8,61,119);
    text-align: justify;
    padding: 0 15px;
}