@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Work+Sans&display=swap');

.featureBox_main{
    width: 18rem;
    height: 18rem;
    background-color: #fffff0;
    padding:1rem;
    border-radius: 0.5rem;
    box-shadow: 12px 12px 32px #9aa3aa;
}
.feature_image img{
    width: 100px;
}
.feature_description>h1{
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
}
.feature_description>p{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    color:#a1a1a1
}
@media screen and (min-width:800px){
    .featureBox_main{
        width: 20rem;
        height: 20rem;
    }
    .feature_description>h1{
        font-size: 24px;
    }
    .feature_description>p{
        font-size: 14px;
        line-height: 20px;
    }
}