@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.Main-Container {
      box-sizing: border-box;
      display: flex;
      /* border: 2px solid red; */
      height: 400px;
      margin-top: 10px;
      width: 100vw;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: 'Wire One', sans-serif;
      background-color: #fffff0;
      /* background-color: black; */

}

.Upper-Container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 0;
      width: 90%;
      /* border: 2px solid red; */

      height: 90%;
}

.Lower-Container {
      width: 100%;
      height: 15%;
      /* border: 1px solid yellow; */
      background-color: rgb(230, 228, 228);
}

.Content {
      margin: 8px;
      height: 94%;
      width: 25%;
      /* border: 2px solid red; */
}

.Content p {
      font-family: Poppins, sans-serif;
      font-weight: 400px;
      font-size: 16px;
      /* color: rgb(83, 83, 232); */
      font-size: 1rem;
      line-height: 1.8;
      cursor: pointer;
}
/* .SecondOne-Contain>button{
      width: fit-content;
      background:transparent;
} */
.Content-Span {
      /* margin: 10px;s */
      font-size: 18px;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 16px;
}

.Content-Span img {
      width: 30%;
      height: 30%;
      margin: 0px;
      margin-top: -20px;
      margin-left: 5px;
}

.Content h2 {
      margin: 1.8rem;
      margin-left: 3rem;
      /* color: rgb(17, 17, 194); */
      font-size: 1.25rem;
      font-family: Poppins, sans-serif;
      font-weight: 600;
      line-height: 1.6;
}

.Image img {
      width: 25%;
      height: 25%;
      margin: 8px;
}

.Copyright{
      display:flex;
      align-items: center;
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
}

.Lower-Contain {
      display: flex;
      justify-content: space-around;
      margin-top: 5px;
}

.Links {
      display: flex;
}

.Links img {
      width: 80%;
      height: 80%;
}

.FifthOne a {
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 16px;
      margin-left: 40px;
      color: rgb(17, 17, 194);
}

.AddressContain p {
      
      margin-left: -9px;
      /* margin-left: 25px; */
      /* color: rgb(17, 17, 194);s */
      font-family: Poppins, sans-serif;
      font-weight: 400px;
      font-size: 16px;
      /* color: rgb(83, 83, 232); */
      font-size: 1rem;
      line-height: 1.8;
      cursor: pointer;
}

.FifthOne h2 {
      margin-left: 38px;
}

.FourthOne a {
      color: black;
}

@media(max-width:1024px) {

      .Main-Container {
            height: 400px;

      }

      .Upper-Container {
            display: flex;
            justify-content: space-evenly;
            margin-top: 0;
            width: 100%;
            /* border: 2px solid red; */

            height: 90%;
      }

      .Lower-Container {
            width: 100%;
            height: 10%;
      }

      .Image img {
            width: 80%;
            height: 80%;
            margin-left: 0px;
            margin-top: 15px;
      }

      .Content-Span img {
            width: 80%;
            height: 80%;
            margin: 0px;
            margin-top: 10px;
            margin-left: 0px;
      }

      .Content {
            display: flex;
            flex-direction: column;
            margin: 4px;
            height: 94%;
            width: 20%;
            /* border: 2px solid red; */
      }

      .Content h2 {
            font-size: 0.8rem;
            margin: 0;
            margin-left: 1.6rem;
      }

      .Content p {
            font-size: 10px;
      }


      .FifthOne h2 {
            margin-left: 0.8rem;
            font-size: 12px;
      }

      .FifthOne a {
            font-family: Poppins, sans-serif;
            font-weight: 400;
            font-size: 10px;
            margin-left: 0.8rem;

      }

      .AddressContain p {
            font-family: Poppins, sans-serif;
            font-weight: 400;
            font-size: 8px;
            /* margin-left: 0.8rem; */
            /* margin-left: 25px; */
            /* color: rgb(17, 17, 194);s */
      }

      .Lower-Container span {
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            font-size: 7px;
      }

      .Lower-Contain {
            display: flex;
            justify-content: space-around;
            margin-top: 2px;
      }


      .Links img {
            width: 50%;
            height: 50%;
      }
}

@media screen and (min-width:700px) and (max-width:1024px) {

      .Image img {
            width: 70%;
            height: 66%;
            margin-left: 0px;
            margin-top: 3px;
      }

      .Content-Span img {
            width: 57%;
            height: 101%;
            margin: 0px;
            margin-top: -27px;
            margin-left: -3px;
      }
}